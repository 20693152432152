const KOL_CATEGORIES = [
  "Tất cả",
  "Thời trang",
  "Thể thao",
  "Sự kiện",
  "Bất động sản",
  "Sức khỏe",
  "Doanh nhân",
  "Thẩm mỹ",
  "Nha khoa",
  "Du lịch",
  "Ẩm thực",
  "Công nghệ",
  "Giáo dục",
  "Livestream/Mẫu ảnh",
  "Khác",
];

const Constants = {
  KOL_CATEGORIES,
  DEFAULT_ERROR_MESSAGE: "Có lỗi xảy ra. Vui lòng thử lại sau",
  API_URL: "https://api.genstar.vn",
  APP_TYPE: process.env.REACT_APP_TYPE,
};

export default Constants;
