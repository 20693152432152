const CommonService = {
  getCredentials: () => {
    const rawCredentials = localStorage.getItem("credentials");
    try {
      return JSON.parse(rawCredentials);
    } catch {
      return null;
    }
  },

  setCredentials: (values) => {
    localStorage.setItem("credentials", JSON.stringify(values));
  },

  removeCredentials: () => {
    localStorage.removeItem("credentials");
  },

  getTokens: () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (accessToken && refreshToken) {
      return {
        accessToken,
        refreshToken,
      };
    }
    return null;
  },

  setTokens: (values) => {
    localStorage.setItem("accessToken", values.access_token);
    localStorage.setItem("refreshToken", values.refresh_token);
  },

  removeTokens: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  },

  clearStorage: () => {
    localStorage.clear();
  },
};

export default CommonService;
