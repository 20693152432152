import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import CommonService from "services/CommonService";

const AppContext = createContext({
  credentials: null,
  setCredentials: () => {},
});

export const useAppContext = () => {
  const data = useContext(AppContext);
  return data;
};

const AppProvider = ({ children }) => {
  const [credentials, setCredentials] = useState(null);

  const handleSetCredentials = useCallback((values) => {
    if (values) {
      setCredentials((preState) => {
        CommonService.setCredentials(
          preState ? { ...preState, ...values } : values
        );
        if (preState) {
          return { ...preState, ...values };
        }
        return values;
      });
    } else {
      setCredentials(null);
    }
  }, []);

  useLayoutEffect(() => {
    const credentials = CommonService.getCredentials();
    setCredentials(credentials);
  }, []);

  return (
    <AppContext.Provider
      value={{ credentials, setCredentials: handleSetCredentials }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
