import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Constants from "config/Constants";
import AppProvider from "context/AppContext";

const AdminRoutes = React.lazy(
  () => import("../src/features/admin/routes/AdminRoutes")
);
const UserRoutes = React.lazy(
  () => import("../src/features/user/routes/UserRoutes")
);

function App() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={
            process.env.REACT_APP_TYPE === "user"
              ? "Genstar - KOL: Nền tảng hàng đầu cho Người có tầm ảnh hưởng. Kết nối với thương hiệu, quản lý chiến dịch và theo dõi tác động của bạn trong một nền tảng mạnh mẽ. Nâng cao sự hiện diện trực tuyến và kiếm tiền từ chuyên môn của bạn với công cụ tiên tiến của Genstar dành cho người sáng tạo nội dung và người có ảnh hưởng trong ngành."
              : "Genstar - Quản trị viên"
          }
        />
      </Helmet>
      <AppProvider>
        <React.Suspense>
          {Constants.APP_TYPE === "user" ? <UserRoutes /> : <AdminRoutes />}
        </React.Suspense>
        <ToastContainer />
      </AppProvider>
    </>
  );
}

export default App;
